import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import Sidebar from 'react-sidebar'
import LogRocket from 'logrocket'
import { clarity } from 'react-microsoft-clarity'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'

import { LicenseManager } from 'ag-grid-enterprise'
import environment from '_environments'

import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'

import Map from '../Map/Map'
import Debug from '../Profile/Debug'
import Profile from '../Profile/Profile'
import Layers from '../Profile/Layers'
import Maps from '../Profile/Maps'
import Users from '../Profile/Users'
import Accounts from '../Profile/Accounts'
import Toc from '../Toc/Toc.jsx'
import Footer from '../Footer/Footer'
import Login from '../Login/Login'
import Reset from '../Login/Reset'
import Verify from '../Login/Verify'
import Loading from './Loading'
import ErrorBoundary from './ErrorBoundary.jsx'
import Error404 from '../Error404'
import MakeDataConfigComponent from '../../utilities/dataConfig'
import { createCredentials, loginInitialize } from '../../utilities/user'
import ProfileSidebar from '../Profile/Sidebar'
import '../CSSModules/freshworks.css'

import Alert from '../../components/Alert'

import scss from './App.scss'

// Action Handlers
import {
  Login as _Login,
  Logout as _Logout,
  updateDataConfig as _updateDataConfig,
  setToc as _setToc,
  Alert as _Alert,
} from '../../actions'

import { keys } from '../../config/keyConfig'
import authConfig from '../../config/authConfig'
import * as utils from '../../utilities/util'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAuthenticating: true,
      fetchingDataConfig: false,
      mountMakeDataConfig: false,
      sidebarOpen: false,
      sidebarTransitions: true,
      helpWidgetActive: false,
    }
  }

  componentDidMount = () => {
    const { SetToc } = this.props
    const state = this
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${keys.google}&libraries=places`
    document.head.append(script)

    Auth.currentSession()
      .then(result => {
        state.props.Login(result.idToken.payload, () => {
          state.setState({ isAuthenticating: false })
        })
      })
      .catch(e => {
        state.setState({ isAuthenticating: false })

        if (e !== 'No current user')
          utils.showMessage('Session Auth Error', e.message)
      })
    // set toc width
    SetToc(false)
    LicenseManager.setLicenseKey(keys.agGrid)
  }

  componentDidUpdate = () => {
    const { fetchingDataConfig } = this.state
    const { user } = this.props
    if (!fetchingDataConfig && user && user.isLoggedIn) {
      // eslint-disable-next-line
      this.setState(
        { fetchingDataConfig: true, mountMakeDataConfig: true },
        () => {
          this.setState({ mountMakeDataConfig: false })
        }
      )
    }
  }

  gotDataConfig = dataConfig => {
    console.log('dataConfig done', dataConfig)
  }

  switchPage = pageName => {
    // eslint-disable-next-line
    this.setState({ showPage: pageName })
  }

  setSidebarOpen = open => {
    this.setState({ sidebarOpen: open })
  }

  setSidebarTransitions = trans => {
    this.setState({ sidebarTransitions: trans })
  }

  handleLogout = () => {
    const state = this
    Auth.signOut()
      .then(() => {
        console.log('Logged out')

        state.props.Logout()
      })
      .catch(e => {
        console.error('Logout Fail: ' + e.message)
        utils.showMessage('Logout Failed', e.message)
      })
  }

  handleLoginAttempt = (success = false, UUID = '') => {
    const { Login: PropsLogin, Logout: PropsLogout } = this.props
    if (success) {
      PropsLogin(UUID)
    } else {
      PropsLogout()
    }
  }

  getMapUi = config => {
    const {
      mountMakeDataConfig,
      sidebarOpen,
      sidebarTransitions,
      helpWidgetActive,
    } = this.state
    const { user, tocCollapsed, SetToc, accountKey } = this.props
    let showMapLoading = true
    let showMap = false
    if (config) {
      showMap = true
      showMapLoading = false
    }

    // Add logRocket if not dev
    if (user.isLoggedIn && !environment.isDev) {
      LogRocket.init('myassetmap/myassetmap')

      LogRocket.identify(user.UUID, {
        name: user.profile.displayName,
        email: user.profile.emailAddress,

        // Add your own custom user variables here, ie:
        accountID: user.accountID,
        mapID: user.mapID,
      })
    }

    const tocWidth = tocCollapsed ? '72px' : 'auto'
    const containerStyle = {
      gridTemplateColumns: `${tocWidth} 1fr`,
    }

    return (
      <>
        {mountMakeDataConfig && (
          <MakeDataConfigComponent
            onFinish={this.gotDataConfig}
            updateStyle={false}
          />
        )}

        {showMap && (
          <div className={scss.sidebarHolder}>
            <Sidebar
              sidebar={
                // eslint-disable-next-line
                <ProfileSidebar
                  setToc={SetToc}
                  setSidebarOpen={this.setSidebarOpen}
                  setSidebarTransitions={this.setSidebarTransitions}
                  logout={this.handleLogout}
                />
              }
              transitions={sidebarTransitions}
              open={sidebarOpen}
              onSetOpen={this.setSidebarOpen}
              styles={{
                root: { zIndex: 2 },
                sidebar: {
                  background: '#1A2937',
                  borderRadius: '0px 10px 10px 0px',
                },
              }}
            >
              <div style={containerStyle} className={scss.container}>
                <Switch>
                  <Route
                    exact
                    path='/(debug|profile|layers|maps|teams|users|accounts)/'
                    render={() => <ProfileSidebar logout={this.handleLogout} />}
                  />
                  <Route
                    exact
                    path='/'
                    render={props => (
                      <Toc
                        {...props}
                        setSidebarOpen={this.setSidebarOpen}
                        dataConfig={config}
                      />
                    )}
                  />
                  <Route render={props => <Error404 {...props} type='map' />} />
                </Switch>

                <main id='main' className={scss.main}>
                  <Switch>
                    <Route exact path='/debug' render={() => <Debug />} />
                    <Route exact path='/profile' render={() => <Profile />} />
                    <Route exact path='/layers' render={() => <Layers />} />
                    <Route exact path='/maps' render={() => <Maps />} />
                    <Route exact path='/users' render={() => <Users />} />
                    <Route exact path='/accounts' render={() => <Accounts />} />
                    <Route
                      path='/'
                      render={props => (
                        <>
                          <Map
                            key={accountKey}
                            {...props}
                            dataConfig={config}
                            noPreferences='noPreferences'
                          />
                        </>
                      )}
                    />
                  </Switch>
                </main>
                <footer id='footer' className={scss.footer}>
                  <Footer />
                </footer>
              </div>
            </Sidebar>
          </div>
        )}

        {showMapLoading && <Loading />}
      </>
    )
  }

  getLoginUi = () => {
    const { isAuthenticating } = this.state
    if (isAuthenticating) return <Loading />

    return (
      <Switch>
        <div style={{ width: '100%', margin: '15% 0' }}>
          <Route
            exact
            path='/'
            render={props => (
              <Login {...props} handleLoginAttempt={this.handleLoginAttempt} />
            )}
          />
          <Route
            path='/login'
            render={props => (
              <Login {...props} handleLoginAttempt={this.handleLoginAttempt} />
            )}
          />
          <Route
            path='/logout'
            render={props => (
              <Login {...props} handleLoginAttempt={this.handleLoginAttempt} />
            )}
          />
          <Route
            path='/reset'
            render={props => (
              <Reset {...props} handleLoginAttempt={this.handleLoginAttempt} />
            )}
          />
          <Route
            path='/verify'
            render={props => (
              <Verify {...props} handleLoginAttempt={this.handleLoginAttempt} />
            )}
          />
          <Route
            path='/(debug|profile|layers|maps|teams|users|accounts)/'
            render={props => (
              <Login {...props} handleLoginAttempt={this.handleLoginAttempt} />
            )}
          />
          <Route render={props => <Error404 {...props} type='login' />} />
        </div>
      </Switch>
    )
  }

  build() {
    const { dataConfig, user } = this.props

    const isLocalEnv =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'

    // Init for Clarity
    if (!isLocalEnv) {
      clarity.init(authConfig.clarity.projectId)

      TagManager.initialize({ gtmId: 'GTM-WQNXNHRQ' })
    } else console.warn('Disabling Clarity & GTM due to localhost env.')

    if (!user) {
      return this.getLoginUi()
    }
    Sentry.setTag('env', environment.env)

    if (user && user.isLoggedIn) {
      // Clarity Setup
      if (!isLocalEnv) {
        clarity.identify(user.UUID, {
          name: user.profile.displayName,
          email: user.profile.emailAddress,

          // Add your own custom user variables here, ie:
          accountID: user.accountID,
          mapID: user.mapID,
        })

        clarity.setTag('env', environment.env)

        // Sentry User setup
        Sentry.setUser({
          name: user.profile.displayName,
          email: user.profile.emailAddress,
        })

        Sentry.setTag('accountID', user.accountID)
        Sentry.setTag('mapID', user.mapID)
      }

      if (window.location.pathname === '/verify') {
        return window.location.replace('/')
      }
      if (user.accounts && user.accounts.length > 0) {
        return this.getMapUi(dataConfig)
      }
      return (
        <div className='text-center pad-lg'>
          <p>It looks like you are not a part of any accounts.</p>
          <p>
            Please talk to your account administrator and have them add you to
            an account.
          </p>
        </div>
      )
    }
    return this.getLoginUi()
  }

  render() {
    const isLocalEnv =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'

    return (
      <ErrorBoundary refreshPage={!isLocalEnv}>{this.build()}</ErrorBoundary>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    activePage: state.switchPage,
    dataConfig: state.updateDataConfig,
    tocCollapsed: state.tocCollapsed,
    accountKey: state.accountKey,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // User Login Actions
    Login: (userPayload, callback = () => {}) => {
      createCredentials().then(async () => {
        console.log(userPayload)
        try {
          const user = await loginInitialize(userPayload)
          if (typeof callback === 'function') callback()
          dispatch(_Login(user))
        } catch (error) {
          console.error('Login', error)
          dispatch(_Alert({ error: error.message }))
        }
      })
    },
    Logout: () => {
      dispatch(_Logout())
    },
    UpdateDataConfig: dataConfig => dispatch(_updateDataConfig(dataConfig)),
    SetToc: collapsed => dispatch(_setToc(collapsed)),
  }
}

App.propTypes = {
  user: PropTypes.shape({
    accounts: PropTypes.arrayOf(PropTypes.shape({})),
    isLoggedIn: PropTypes.bool.isRequired,
    accountID: PropTypes.number,
    mapID: PropTypes.number,
    UUID: PropTypes.string.isRequired,
    profile: PropTypes.shape({
      displayName: PropTypes.string,
      emailAddress: PropTypes.string,
    }).isRequired,
  }),
  SetToc: PropTypes.func.isRequired,
  Login: PropTypes.func.isRequired,
  Logout: PropTypes.func.isRequired,
  tocCollapsed: PropTypes.bool.isRequired,
  accountKey: PropTypes.string.isRequired,
  dataConfig: PropTypes.shape({}),
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
