/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import ReactTooltip from 'react-tooltip'
import { apiFetch } from '../../../../utilities/api'
import { apis } from '../../../../config/apiConfig'
import {
  setToolConfig,
  mountLayerSettings,
  updateMapStyle,
  Alert,
} from '../../../../actions/index'

import scss from './Documents.scss'
import attributesScss from './Attributes.scss'
import './LayerAttributes.css'
import appScss from '../../../App/App.scss'

import EditAttributeV2 from './EditAttributeV2.jsx'
import DocumentPropertyRow from './DocumentPropertyRow'
import DocumentBrowserV2 from '../../../DocumentBrowserV2/DocumentBrowserV2'
import * as DocumentBrowserUtil from '../../../DocumentBrowserV2/DocumentBrowserV2Util'

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToolConfig,
      mountLayerSettings,
      updateMapStyle,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
    toolConfig: state.toolConfig,
    mountKey: state.mountLayerSettings,
    mapStyle: state.mapStyle,
  }
}

const LayerDocumentsV2 = ({ config, setDisplayManageAttributesMenu }) => {
  const [targetProperty, setTargetProperty] = useState(null)
  const [layerProperties, setLayerProperties] = useState(null)
  const [addNewUi, setAddNewUi] = useState(false)
  const [deleteUi, setDeleteUi] = useState(false)
  const [error, setError] = useState(null)
  const [displayFileBrowser, setDisplayFileBrowser] = useState(false)
  const [editing, setEditing] = useState()
  const [saving, setSaving] = useState()
  const [deleteTarget, setDeleteTarget] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)

  const [editMode, setEditMode] = useState(false)

  const dispatch = useDispatch()

  const [currentDocumentLayerProperty, setCurrentDocumentLayerProperty] =
    useState()

  const getProperties = () => {
    const layerId = config.layer.layersArray[0].layer.id
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/properties/get'
    const bodyParams = {
      layerID: layerId,
    }
    apiFetch(url, method, bodyParams, result => {
      if (result.success) {
        setLayerProperties(result.data)
      } else {
        setLayerProperties([])
      }
    })
  }

  const handleDocumentChange = (layerType, awsLink, fileName) => {
    const url = apis.apiDatabase.uri + '/layer/properties/documentlayer/update'
    const method = 'POST'
    const layerPropertyID = currentDocumentLayerProperty.id
    const propertyKey = currentDocumentLayerProperty.key
    const value = awsLink

    const bodyParams = {
      layerPropertyID,
      propertyKey,
      value,
    }

    apiFetch(url, method, bodyParams, result => {
      if (result.success) {
        dispatch(
          Alert({
            success: 'File associated',
          })
        )
        getProperties()
      } else {
        dispatch(
          Alert({
            error: 'Unable to associate file',
          })
        )
      }
    })
  }

  const handleDocumentUnlink = (layerPropertyID, propertyKey) => {
    const bodyParams = {
      layerPropertyID,
      propertyKey,
      value: '',
    }
    const url = apis.apiDatabase.uri + '/layer/properties/documentlayer/update'
    const method = 'POST'
    apiFetch(url, method, bodyParams, result => {
      if (result.success) {
        dispatch(
          Alert({
            success: 'File unlinked',
          })
        )
        getProperties()
      } else {
        dispatch(
          Alert({
            error: 'Unable to unlink file',
          })
        )
      }
    })
  }

  const getDocumentNameFromLink = link => {
    if (!link) return
    let fileName = 'N/A'

    fileName = link.split('/')
    fileName = fileName[fileName.length - 1]
    fileName = fileName.replace('|', '')

    return fileName
  }

  const getIcon = fileName => {
    fileName = String(fileName).toUpperCase()

    // if not . then it is a folder
    if (!fileName.includes('.'))
      return (
        <FontAwesomeIcon
          icon='folder'
          style={{
            color: 'rgb(92, 141, 163)',
            marginLeft: '5px',
            marginRight: '5px',
          }}
        />
      )

    let fileExtension = fileName.split('.')
    fileExtension = fileExtension[fileExtension.length - 1]

    if (fileExtension === 'PDF') {
      return (
        <FontAwesomeIcon
          icon='file-pdf'
          style={{
            color: 'white',
            marginLeft: '5px',
            marginRight: '5px',
          }}
        />
      )
    }

    if (
      [
        'JPEG',
        'JPG',
        'PNG',
        'GIF',
        'TIFF',
        'EPS',
        'INDD',
        'RAW',
        'BIMP',
        'TIF',
        'TIFF',
        'PSD',
      ].includes(fileExtension)
    ) {
      return (
        <FontAwesomeIcon
          icon='file-image'
          style={{
            color: 'white',
            marginLeft: '5px',
            marginRight: '5px',
          }}
        />
      )
    }

    return (
      <FontAwesomeIcon
        icon='file'
        style={{
          color: 'white',
          marginLeft: '5px',
          marginRight: '5px',
        }}
      />
    )
  }

  const generatePropertyLevel = property => {
    if (!property.type) {
      console.log('No property type was provided')
      return
    }
    let propertyLevel = ''

    if (property.type.includes('(Layer)')) {
      propertyLevel = (
        <span title='Layer level document' style={{ marginLeft: '5px' }}>
          {' '}
          (L)
        </span>
      )
    }

    if (property.type.includes('(Feature)')) {
      propertyLevel = (
        <span title='Feature level document' style={{ marginLeft: '5px' }}>
          {' '}
          (F)
        </span>
      )
    }

    return propertyLevel
  }

  const getPropertyList = () => {
    if (!layerProperties) return
    let list = null

    if (layerProperties) {
      list = layerProperties.map(property => {
        if (!property || !property.type) return
        if (!(property.type === 'document (Layer)')) return

        const propertyLevel = generatePropertyLevel(property) || null
        const propertyName = property.name
        const propertyLink = property.value[0] || null
        const documentName = getDocumentNameFromLink(propertyLink) || ''

        const propertyDocumentTypeIcon = getIcon(documentName) || null
        return (
          <DocumentPropertyRow
            key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(10)}
            property={property}
            propertyName={propertyName}
            propertyLink={propertyLink}
            documentName={documentName}
            propertyDocumentTypeIcon={propertyDocumentTypeIcon}
            propertyLevel={propertyLevel}
            setCurrentDocumentLayerProperty={setCurrentDocumentLayerProperty}
            handleDocumentUnlink={handleDocumentUnlink}
            setTargetProperty={setTargetProperty}
            setDisplayFileBrowser={setDisplayFileBrowser}
            setEditMode={setEditMode}
          />
        )
      })
      list = list.filter(item => typeof item !== 'undefined')
    }

    return (
      <>
        <div
          className={[
            scss.layerDocumentsGridBody,
            attributesScss.layerPropertiesGridBody,
          ].join(' ')}
          style={{ gridTemplateRows: 'none' }}
        >
          {list && list.length > 0 ? (
            list
          ) : (
            <span style={{ fontSize: '0.85em', padding: '5%' }}>
              No layer level documents have been added.
            </span>
          )}
        </div>
      </>
    )
  }

  const getLayerAttributes = () => {
    if (!layerProperties)
      return (
        <SkeletonTheme color='#e4e5e7' highlightColor='#e8ebf2'>
          <div className={attributesScss.layerPropertiesGridRow}>
            <span>
              <Skeleton height={14} count={1} />
            </span>
            <span>
              <Skeleton height={14} count={1} />
            </span>
            <span>
              <Skeleton height={14} count={1} />
            </span>
          </div>
        </SkeletonTheme>
      )

    const propertyList = getPropertyList()

    return <>{propertyList}</>
  }
  const addNewProperty = () => {
    const newTargetProperty = {
      name: '',
      default: '',
      type: 'text',
      value: [],
    }
    setAddNewUi(true)
    setTargetProperty(newTargetProperty)
  }

  const getDropDownOptionList = () => {
    return
  }

  const cancelAddNew = () => {
    setAddNewUi(false)
    setError(null)
  }

  const deleteModal = () => setDeleteUi(true)

  useEffect(() => {
    getProperties()
    setDisplayManageAttributesMenu(false)
  }, [])

  const finishUpdate = () => {
    // setEditing(null)
    // setLayerProperties(null)
    // setTargetProperty(null)
    // setSaving(false)
    // setAddNewUi(false)
    // setDeleteUi(false)
    // setDeleteTarget(null)
    // setError(null)
    // setSelectedOption({ label: 'Text', value: 'text' })
    setEditMode(false)
    setMountMakeDataConfig(true)
  }

  useEffect(() => {
    setMountMakeDataConfig(false)
  }, [mountMakeDataConfig])

  return (
    <>
      {displayFileBrowser ? (
        <DocumentBrowserV2
          linkMode
          onClose={() => {
            setDisplayFileBrowser(false)
          }}
          handleDocumentChange={handleDocumentChange}
        />
      ) : null}

      {editMode ? (
        <EditAttributeV2
          config={config}
          targetProperty={targetProperty}
          getDropDownOptionList={getDropDownOptionList}
          cancelEdit={() => {
            setEditMode(false)
          }}
          getProperties={getProperties}
          finishUpdate={finishUpdate}
        />
      ) : (
        <>
          <div style={{ height: '100%' }}>
            <div
              className={scss.DocumentsTitleContainer}
              style={{ background: 'none' }}
            >
              <div className={scss.DocumentsTitle}>
                Layer Level Document Attributes
              </div>
            </div>
            {layerProperties &&
            layerProperties.length &&
            layerProperties.length > 0 ? (
              getPropertyList()
            ) : (
              <div
                style={{
                  height: '90%',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <span>
                  Layer Level Document Attributes created by your manager will
                  appear here
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LayerDocumentsV2)
