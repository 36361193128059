import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMapMode, setToc } from '../../actions/index'
import scss from './Sidebar.scss'
import appScss from '../App/App.scss'
import ToggleToc from './TocControls/TocToggle'
import ReactTooltip from 'react-tooltip'
import * as utils from '../../utilities/util'
import DocumentBrowserV2 from '../DocumentBrowserV2/DocumentBrowserV2'
import dataIcon from '../../assets/icons/sidebar/DataLayers.svg'
import profileIcon from '../../assets/icons/sidebar/Hamburger.svg'
import mapViewsIcon from '../../assets/icons/sidebar/MapViews.svg'
import printIcon from '../../assets/icons/sidebar/Print.svg'
import importIcon from '../../assets/icons/sidebar/Import.svg'
import documentsIcon from '../../assets/icons/sidebar/documents.svg'

const SideNav = React.memo(
  ({
    setSidebarOpen,
    setActivePanel,
    currentPanel,
    toolConfig,
    toggleTool,
  }) => {
    const dispatch = useDispatch()
    const [accountImgURL, setAccountImgURL] = useState(
      'https://my-asset-map-data.s3.amazonaws.com/public/client_data/static/images/account.png'
    )
    const [openDocumentBrowser, setOpenDocumentBrowser] = useState(false)
    const mapMode = useSelector(state => state.mapMode)
    const user = useSelector(state => state.user)
    const account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

    const handleDocumentBrowser = () => {
      setOpenDocumentBrowser(!openDocumentBrowser)
    }

    const handleDocumentBrowserClose = useCallback(() => {
      setOpenDocumentBrowser(false)
    })

    const openSidebarSlideout = () => {
      dispatch(setToc(true))
      setSidebarOpen(true)
    }

    const toggleMapMode = () => {
      // Note: changes the mapMode, we do not need to set an Active Panel
      const newMode = mapMode === 'MAP' ? 'PRINT' : 'MAP'
      dispatch(setMapMode(newMode))
      dispatch(setToc(newMode === 'MAP' ? false : true))
    }

    const isToolActive = name => {
      var active
      if (Array.isArray(toolConfig)) {
        toolConfig.forEach(tool => {
          if (tool.name == name) {
            active = tool.visible
          }
        })
        if (active == null)
          console.warn(
            "Name ('" + name + "') not found in toolConfig",
            toolConfig.length
          )
        return active == null ? false : active
      }
      return false
    }

    useEffect(() => {
      async function getAccountImage() {
        const imgURL = await utils.getImage(account.profileImg, 'account.png')

        setAccountImgURL(imgURL)
      }
      getAccountImage()
    }, [user])

    return (
      <div className={scss.tocTab}>
        <nav className={scss.tocNav}>
          <div className={scss.tocNavTop}>
            <div className={scss.tocNavHeader}>
              <div
                className={[scss.tocTabIconDiv, scss.menu].join(' ')}
                onClick={openSidebarSlideout}
              >
                <span
                  className={scss.tocTabIcons}
                  onClick={() => setSidebarOpen(true)}
                >
                  <img src={profileIcon} alt='Profile' />
                </span>
              </div>
            </div>
            <div className={scss.tocNavBodyBottom}>
              <ToggleToc currentPanel={currentPanel} childPanel={'data'}>
                <div className={scss.tocButtonBorder}>
                  <div
                    className={[
                      scss.tocTabIconDiv,
                      currentPanel == 'data' ? scss.tocTabIconsDivActive : '',
                    ].join(' ')}
                    data-tip='Data Layers'
                    data-for='data-layers'
                    data-effect='solid'
                    data-arrow-color='rgba(0, 0, 0, 0.68)'
                  >
                    <span
                      className={[scss.tocTabIcons, scss.tocTabDataIcon].join(
                        ' '
                      )}
                      onClick={() => setActivePanel('data')}
                    >
                      <img src={dataIcon} alt='Data Layers' />
                    </span>
                  </div>
                </div>
              </ToggleToc>
              <div className={appScss.reactToolTip}>
                <ReactTooltip
                  id='data-layers'
                  getContent={dataTip => `${dataTip}`}
                />
              </div>
              <ToggleToc currentPanel={currentPanel} childPanel={'mapviews'}>
                <div className={scss.tocButtonBorder}>
                  <div
                    className={[
                      scss.tocTabIconDiv,
                      currentPanel == 'mapviews'
                        ? scss.tocTabIconsDivActive
                        : '',
                    ].join(' ')}
                    data-tip='Map Views'
                    data-for='map-views'
                    data-effect='solid'
                    data-arrow-color='rgba(0, 0, 0, 0.68)'
                  >
                    <span
                      className={scss.tocTabIcons}
                      onClick={() => setActivePanel('mapviews')}
                    >
                      <img src={mapViewsIcon} alt='Map Views' />
                    </span>
                  </div>
                </div>
              </ToggleToc>
              <div className={appScss.reactToolTip}>
                <ReactTooltip
                  id='map-views'
                  getContent={dataTip => `${dataTip}`}
                />
              </div>
              <div
                className={[
                  scss.tocTabIconDiv,
                  isToolActive('Documents') ? scss.tocTabIconsDivActive : '',
                ].join(' ')}
                data-tip='Documents'
                data-for='documents'
                data-effect='solid'
                data-arrow-color='rgba(0, 0, 0, 0.68)'
              >
                <span
                  className={scss.tocTabIcons}
                  onClick={() => handleDocumentBrowser()}
                >
                  <img src={documentsIcon} alt='Documents' />
                </span>
              </div>
              <div className={appScss.reactToolTip}>
                <ReactTooltip
                  id='documents'
                  getContent={dataTip => `${dataTip}`}
                />
              </div>
              {openDocumentBrowser && (
                <DocumentBrowserV2
                  featureProperties={null}
                  layerId={null}
                  mamId={null}
                  documentFeature={null}
                  layerProperties={null}
                  onClose={() => handleDocumentBrowserClose()}
                />
              )}
              <div
                className={[
                  scss.tocTabIconDiv,
                  isToolActive('DataImport') ? scss.tocTabIconsDivActive : '',
                ].join(' ')}
                data-tip='Import'
                data-for='import'
                data-effect='solid'
                data-arrow-color='rgba(0, 0, 0, 0.68)'
              >
                <span
                  className={scss.tocTabIcons}
                  onClick={() => toggleTool('DataImport')}
                >
                  <img src={importIcon} alt='Import' />
                </span>
              </div>
              <div className={appScss.reactToolTip}>
                <ReactTooltip
                  id='import'
                  getContent={dataTip => `${dataTip}`}
                />
              </div>

              <div
                className={[
                  scss.tocTabIconDiv,
                  currentPanel == 'print' ? scss.tocTabIconsDivActive : '',
                ].join(' ')}
                data-tip='Print'
                data-for='print'
                data-effect='solid'
                data-arrow-color='rgba(0, 0, 0, 0.68)'
              >
                <span className={scss.tocTabIcons} onClick={toggleMapMode}>
                  <img src={printIcon} alt='Print' />
                </span>
              </div>
              <div className={appScss.reactToolTip}>
                <ReactTooltip id='print' getContent={dataTip => `${dataTip}`} />
              </div>
            </div>
          </div>
          <div className={scss.tocNavFooter}>
            <img
              src={accountImgURL}
              className={appScss.roundPic}
              style={{ width: '35px', height: '35px' }}
              alt='User account'
            />
          </div>
        </nav>
      </div>
    )
  }
)

export default SideNav
